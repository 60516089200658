import React from 'react';
import './App.css';
import liteImage from './lite.png';
import appleLogo from './apple.png';
import microsoftLogo from './microsoft.png';
import linuxLogo from './linux.png';

function App() {
  return (
    <div className="App">
      <div className="version-text">LITE alpha03</div>
      <div className="white-section">
        <img src={liteImage} alt="Lite" className="center-image"/>
        <div className="buttons-container">
          <div className="button-container">
            <a href="/LITE_osx_1.0.0-alpha03.pkg" className="button">
              <img src={appleLogo} alt="Apple Logo" className="logo"/>
              Download for macOS
            </a>
            <p>Architectures: ARM64</p>
          </div>
          <div className="button-container">
            <a href="/LITE_win_1.0.0-alpha02.exe" className="button">
              <img src={microsoftLogo} alt="Microsoft Logo" className="logo"/>
              Download for Windows
            </a>
            <p>Architectures: x86_64</p>
          </div>
          <div className="button-container disabled">
            <div className="button">
              <img src={linuxLogo} alt="Linux Logo" className="logo"/>
              Download for Linux
            </div>
            <p>COMING SOON</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
